<template>
  <section class="container">
    <!-- 筛选 -->
    <el-form class="query-form" inline style="position: relative">
      <section data-text="组织机构查询">
        <el-form-item label="姓名">
          <el-input
            v-model="query.name"
            size="small"
            placeholder="请输入"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="电话">
          <el-input
            v-model="query.mobile"
            size="small"
            placeholder="请输入"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="机构/国别/项目">
          <el-cascader
            :options="organizationTree"
            :props="{ multiple: true, checkStrictly: true }"
            v-model="organization"
            clearable
            @change="treeChange"
            @remove-tag="treeChange"
            ref="treeSelect"
            size="small"
          ></el-cascader>
        </el-form-item>
      </section>
      <section data-text="隔离/监测" v-show="!fold">
        <el-form-item label="隔离类型">
          <el-select
            v-model="query.isolateType"
            size="small"
            placeholder="请选择"
            clearable
          >
            <el-option
              v-for="item in isolateTypes"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="隔离日期">
          <el-date-picker
            v-model="isolateTime"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            size="small"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd hh:mm:ss"
            @change="changeTime($event, 'vaccine')"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="">
          <el-checkbox
            label="密切接触者"
            value="1"
            v-model="query.isClose"
          ></el-checkbox>
        </el-form-item>
        <el-form-item label="">
          <el-checkbox
            label="次密切接触者"
            value="1"
            v-model="query.isSubClose"
          ></el-checkbox>
        </el-form-item>
      </section>
      <section data-text="核酸检测" v-show="!fold">
        <el-form-item label="核酸检测">
          <el-radio-group v-model="query.isCheck">
            <el-radio
              :label="item.value"
              :value="item.value"
              v-for="item in nuclearChoose"
              :key="item.id"
              >{{ item.label }}</el-radio
            >
          </el-radio-group>
        </el-form-item>
        <el-form-item label="检测结果">
          <el-radio-group v-model="query.result">
            <el-radio
              :label="item.value"
              :value="item.value"
              v-for="item in nuclearResult"
              :key="item.id"
              >{{ item.label }}</el-radio
            >
          </el-radio-group>
        </el-form-item>
        <el-form-item label="检测日期">
          <el-date-picker
            v-model="vaccineTime"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            size="small"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd hh:mm:ss"
            @change="changeTime($event, 'nucleic')"
          >
          </el-date-picker>
        </el-form-item>
      </section>
      <section data-text="接种疫苗" v-show="!fold">
        <el-form-item label="接种疫苗">
          <el-radio-group v-model="query.isVac">
            <el-radio
              :label="item.value"
              :value="item.value"
              v-for="item in vaccineChoose"
              :key="item.id"
              >{{ item.label }}</el-radio
            >
          </el-radio-group>
        </el-form-item>
        <!-- <el-form-item label="接种疫苗">
          <el-select
            v-model="query.isVac"
            size="small"
            placeholder="请选择"
            clearable
          >
            <el-option
              v-for="item in vaccineChoose"
              :key="item.id"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item> -->
        <el-form-item label="疫苗类型">
          <el-select
            v-model="query.vacType"
            size="small"
            placeholder="请选择"
            clearable
          >
            <el-option
              v-for="item in vaccineTypes"
              :key="item.value"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="接种次数">
          <el-select
            v-model="query.vacCount"
            size="small"
            placeholder="请选择"
            clearable
          >
            <el-option
              v-for="item in vaccineCount"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </section>
      <section data-text="行程查询" v-show="!fold">
        <el-form-item label="交通工具">
          <el-select
            v-model="query.tripWayId"
            size="small"
            placeholder="请选择"
            clearable
          >
            <el-option
              v-for="item in transport"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="班次查询">
          <el-input
            v-model="query.tripNo"
            size="small"
            placeholder="请输入"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="行程日期">
          <el-date-picker
            v-model="tripTime"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            size="small"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd hh:mm:ss"
            @change="changeTime($event, 'trip')"
          >
          </el-date-picker>
        </el-form-item>

        <el-form-item label="风险等级">
          <el-select
            v-model="query.inLevel"
            size="small"
            placeholder="请选择"
            clearable
          >
            <el-option
              v-for="item in levels"
              :key="item.id"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </section>
      <section data-text="位置查询" v-show="!fold">
        <el-form-item label="">
          <el-radio-group v-model="query.inChina">
            <el-radio label="0">中国</el-radio>
            <el-radio label="1">海外</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="地理位置" v-if="query.inChina == 0">
          <el-cascader
            filterable
            size="small"
            :options="options_"
            @change="changePosition"
            v-model="address"
            placeholder="请选择"
            :props="{ checkStrictly: true, value: 'label' }"
            clearable=""
          ></el-cascader>
        </el-form-item>
        <el-form-item label="国家名称" v-else>
          <el-select
            v-model="query.abroadName"
            size="small"
            placeholder="请选择"
            clearable
            filterable
          >
            <el-option
              v-for="item in countries"
              :key="item.id"
              :label="item.name"
              :value="item.name"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </section>
      <el-form-item>
        <el-button
          type="primary"
          size="small"
          @click="search"
          @keydown.enter="search"
          >查询</el-button
        >
      </el-form-item>
      <el-form-item>
        <el-button size="small" @click="newReset()">重置</el-button>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" size="small" @click="exportData"
          >导出数据</el-button
        >
      </el-form-item>
      <p class="fold" @click="fold = !fold">
        {{ !fold ? "收起" : "展开更多条件" }}
        <i :class="fold ? 'el-icon-bottom' : 'el-icon-top'"></i>
      </p>
    </el-form>
    <!-- 展示 -->
    <section class="main-content">
      <el-table :data="list" style="width: 100%" border stripe>
        <el-table-column prop="name" label="姓名"> </el-table-column>
        <el-table-column prop="mobile" label="电话"> </el-table-column>
        <el-table-column prop="institution" label="机构"> </el-table-column>
        <el-table-column prop="country" label="国别"> </el-table-column>
        <el-table-column prop="project" label="项目"> </el-table-column>
        <el-table-column label="隔离状态">
          <template slot-scope="scope">
            <el-tag :type="!!scope.row.nowStatus ? 'danger' : ''">{{
              scope.row.nowStatus || "正常"
            }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="人员类型">
          <template slot-scope="scope">
            {{
              scope.row.type == 0
                ? "自有人员"
                : scope.row.type == 1
                ? "外聘人员"
                : "务工人员"
            }}
          </template>
        </el-table-column>
        <el-table-column label="风险状态">
          <template slot-scope="scope">
            <el-tag
              v-if="scope.row.inLevel"
              :type="
                scope.row.inLevel == 1
                  ? 'info'
                  : scope.row.inLevel == 2
                  ? 'warning'
                  : 'danger'
              "
            >
              {{
                scope.row.inLevel == 1
                  ? "低风险地区"
                  : scope.row.inLevel == 2
                  ? "中风险地区"
                  : "高风险地区"
              }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column label="体温历史">
          <template slot-scope="scope">
            <el-button type="text" @click="showTempDialog('temp', scope.row)"
              >查看</el-button
            >
          </template>
        </el-table-column>
        <el-table-column label="隔离历史">
          <template slot-scope="scope">
            <el-button type="text" @click="showTempDialog('isolate', scope.row)"
              >查看</el-button
            >
          </template>
        </el-table-column>
        <el-table-column label="核酸检测记录">
          <template slot-scope="scope">
            <el-button type="text" @click="trigger('n', scope.row)"
              >查看</el-button
            >
          </template>
        </el-table-column>
        <el-table-column label="疫苗检测记录">
          <template slot-scope="scope">
            <el-button type="text" @click="trigger('v', scope.row)"
              >查看</el-button
            >
          </template>
        </el-table-column>
        <el-table-column label="行程记录">
          <template slot-scope="scope">
            <el-button type="text" @click="showDialog(scope.row)"
              >查看</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        background
        layout="total,sizes,prev, pager, next"
        style="text-align: right; margin-top: 20px"
        @size-change="sizeChange"
        @prev-click="prevClick"
        @next-click="nextClick"
        @current-change="pageCurrent"
        :page-size="query.ps"
        :total="total"
      >
      </el-pagination>
    </section>

    <!-- 行程记录弹窗 -->
    <tripdialog ref="trip"></tripdialog>
    <!-- 体温 -->
    <el-dialog
      :title="dialogTitle"
      :visible.sync="tempdialogVisible"
      width="30%"
    >
      <div style="max-height: 500px; overflow-y: scroll">
        <template v-if="currentType == 'temp'">
          <el-timeline v-if="tempHistory.length">
            <el-timeline-item
              v-for="(item, index) in tempHistory"
              :key="index"
              :timestamp="formatTime(item.time)"
            >
              <p>体温：{{ item.temperature }}度</p>
              <p v-if="item.isAbnormal == 1">
                不适症状：
                {{
                  item.abnormal
                    ? item.abnormal.name === "其他"
                      ? item.remark
                      : item.abnormal.name
                    : ""
                }}
              </p>
            </el-timeline-item>
          </el-timeline>
          <p v-else style="color: #999; text-align: center">
            该用户暂无体温记录
          </p>
        </template>
        <template v-else>
          <el-timeline v-if="isolateHistory.length">
            <el-timeline-item
              v-for="(item, index) in isolateHistory"
              :key="index"
              :timestamp="formatTime(item.createTime)"
            >
              <p>
                时间：{{ formatTime(item.startTime) }}至{{
                  formatTime(item.endTime)
                }}
              </p>
              <p>
                地点：{{ item.province + item.city + item.district
                }}{{ item.addressDetail }}
              </p>
              <p>{{ item.isolateType }}</p>
            </el-timeline-item>
          </el-timeline>
          <p v-else style="color: #999; text-align: center">
            该用户暂无隔离记录
          </p>
        </template>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="tempdialogVisible = false">取 消</el-button>
      </span>
    </el-dialog>

    <!-- ---------------------------  接种记录  -------------------------------- -->
    <el-dialog :title="dialogTitle" :visible.sync="dialogVisible" width="30%">
      <div style="max-height: 500px; overflow-y: scroll">
        <template v-if="currentType == 'n'">
          <el-timeline v-if="nList.length">
            <el-timeline-item
              v-for="(item, index) in nList"
              :key="index"
              :timestamp="formatTime(item.time)"
            >
              <p>检测地点：{{item.address}}</p>
              <p>检测结果：{{item.result?'阳性':'阴性'}}</p>
            </el-timeline-item>
          </el-timeline>
          <p v-else style="color: #999; text-align: center">
            该用户暂无检测记录
          </p>
        </template>
        <template v-else>
          <el-timeline v-if="vList.length">
            <el-timeline-item
              v-for="(item, index) in vList"
              :key="index"
              :timestamp="formatTime(item.time) + ` 第${item.count||1}针`"
            >
              <p>接种类型：{{item.vaccinesType&&item.vaccinesType.name}}</p>
              <p>接种地点：{{item.address}}</p>
            </el-timeline-item>
          </el-timeline>
          <p v-else style="color: #999; text-align: center">
            该用户暂无接种记录
          </p>
        </template>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
      </span>
    </el-dialog>
  </section>
</template>

<script>
import {
  List,
  TripDetail,
  VaccineList,
  Organization,
  Transport,
  ExportData,
  IsolateType,
  CountryList,
} from "@/api/person/status";
import {
  epidemicList,
  epidemicTypes,
  nucleicList,
  vaccinesList,
} from "@/api/api";
import {
  provinceAndCityData,
  regionData,
  provinceAndCityDataPlus,
} from "element-china-area-data";
import tripdialog from "@/components/trip";
import format from "@/mixins/format";
import page from "@/mixins/page";
import { tempList, tempDetail, isolateDetail } from "@/api/api";
export default {
  data() {
    return {
      options_: regionData,
      list: [],
      query: {
        ps: 20,
        inChina: null,
      },
      tripTime: "",
      isolateTime: "",
      total: 0,
      dialogVisible: false,
      organization: "",
      history: [],
      vaccineTypes: [], //疫苗类型
      nuclearChoose: [
        { label: "全部", value: "" },
        {
          label: "已检测",
          value: 1,
        },
        {
          label: "未检测",
          value: 0,
        },
      ],
      vaccineChoose: [
        { label: "全部", value: "" },
        {
          label: "已接种",
          value: 1,
        },
        {
          label: "未接种",
          value: 0,
        },
      ], //是否接种疫苗
      vaccineCount: [
        {
          label: "一次",
          value: 1,
        },
        {
          label: "二次",
          value: 2,
        },
        {
          label: "三次",
          value: 3,
        },
        {
          label: "三次以上",
          value: 4,
        },
      ],
      nuclearResult: [
        { label: "全部", value: "" },
        {
          label: "阴性",
          value: 0,
        },
        {
          label: "阳性",
          value: 1,
        },
      ],
      nuclearTime: "",
      vaccineTime: "",
      organizationTree: [],
      transport: [
        {
          id: 2,
          name: "飞机",
        },
        {
          id: 1,
          name: "火车",
        },
        {
          id: 3,
          name: "其他",
        },
      ],
      levels: [
        {
          label: "高风险",
          value: 3,
        },
        {
          label: "中风险",
          value: 2,
        },
        {
          label: "低风险",
          value: 1,
        },
      ],
      isolateTypes: [],
      countries: [],
      fold: true,
      address: "",
      inChina: "",
      procinveCityDistrict: "",
      allCountries: [],
      currentType: "",
      dialogTitle: "",
      tempdialogVisible: false,
      isolateHistory: [],
      vList:[],
      nList:[]
    };
  },
  computed: {
    provinceCityShow() {
      if (this.query.userAlwaysCountry != "中国") {
        this.query.userProvince = null;
        this.query.userCity = null;
        this.query.userDistrict = null;
      }
      return this.query.userAlwaysCountry == "中国";
    },
  },
  mixins: [format, page],
  mounted() {
    this.getVaccineType();
    this.getOrganization();
    // this.getTransport();
    this.getIsoType();
    this.getCountries();
    this.getList(this.query);
  },
  methods: {
    // 弹窗
    trigger(type, obj) {
      this.dialogVisible = true
      this.currentType = type
      if (type == "n") {
        this.dialogTitle = '核酸检测记录'
        nucleicList({ mobile: obj.mobile }).then((res) => {
          this.nList = res.data.data;
        });
      } else {
        this.dialogTitle = '接种疫苗记录'
        vaccinesList({ mobile: obj.mobile }).then((res) => {
          this.vList = res.data.data;
        });
      }
    },
    changeProcinveCityDistrict(e) {
      if (e) {
        this.query.userProvince = e[0];
        this.query.userCity = e[1];
        this.query.userDistrict = e[2];
      }
    },
    newReset() {
      this.organization = "";
      this.isolateTime = "";
      this.vaccineTime = "";
      this.tripTime = "";
      this.address = "";
      this.reset();
    },
    //   列表
    async getList(query) {
      let res = await List(query);
      this.list = res.data.data.list;
      this.total = res.data.data.total;
    },
    // 疫苗类型
    async getVaccineType() {
      let res = await VaccineList();
      this.vaccineTypes = res.data.data;
    },
    // 获取组织架构
    async getOrganization() {
      let res = await Organization();
      this.organizationTree = this.setTreeData(res.data.data);
    },
    async getTransport() {
      let res = await Transport();
      this.transport = res.data.data;
    },
    async getIsoType() {
      let res = await IsolateType();
      this.isolateTypes = res.data.data;
    },
    async getCountries() {
      let res = await CountryList();
      this.countries = res.data.data.filter((item) => item.name != "中国");
      this.allCountries = res.data.data;
    },
    showTempDialog(type, obj) {
      if (!obj.mobile) {
        this.$message.error("该用户未提交记录");
        return;
      }

      if (type == "temp") {
        tempDetail({ mobile: obj.mobile }).then((res) => {
          this.currentType = type;
          this.dialogTitle = type == "temp" ? "体温历史" : "隔离历史";
          this.tempdialogVisible = true;
          this.tempHistory = res.data.data;
        });
      } else {
        isolateDetail({ mobile: obj.mobile }).then((res) => {
          this.currentType = type;
          this.dialogTitle = type == "temp" ? "体温历史" : "隔离历史";
          this.tempdialogVisible = true;
          this.isolateHistory = res.data.data;
        });
      }
    },
    setTreeData(arr) {
      let result = [];
      arr.forEach((ele) => {
        let item = {
          label: ele.name,
          value: ele.name,
          type: ele.type,
        };
        if (ele.secOrganizes.length) {
          item.children = this.setTreeData(ele.secOrganizes);
        }
        result.push(item);
      });
      return result;
    },
    changePosition(e) {
      this.query.province = e[0];
      this.query.city = e[1];
      this.query.district = e[2];
    },
    // 行程记录
    showDialog(obj) {
      this.$refs.trip.show(obj.mobile);
    },
    changeTime(e, type) {
      let start = e && e[0] ? e[0].substr(0, 10) + " 00:00:00" : null;
      let end = e && e[1] ? e[1].substr(0, 10) + " 23:59:59" : null;
      switch (type) {
        case "nucleic":
          this.query.nucleicStartTime = start;
          this.query.nucleicEndTime = end;
          break;
        case "trip":
          this.query.tripStartTime = start;
          this.query.tripEndTime = end;
          break;
        case "vaccine":
          this.query.isolateStartTime = start;
          this.query.isolateEndTime = end;
          break;
      }
    },
    exportData() {
      ExportData(this.query).then((res) => {
        const data = res.data;
        const url = window.URL.createObjectURL(
          new Blob([data], {
            type:
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          })
        );
        const link = document.createElement("a");
        link.style.display = "none";
        link.href = url;
        link.setAttribute("download", "人员查询.xlsx");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
    },
    treeChange(e) {
      let res = this.$refs.treeSelect.getCheckedNodes();
      let institution = [];
      let country = [];
      let project = [];
      setTimeout(() => {
        res.forEach((item) => {
          if (item.checked) {
            if (item.data.type == 0) {
              institution.push(item.data.value);
            }
            if (item.data.type == 1) {
              country.push(item.data.value);
            }
            if (item.data.type == 2) {
              project.push(item.data.value);
            }
          }
        });
        this.query.institution = institution.length
          ? institution.join(",")
          : null;
        this.query.country = country.length ? country.join(",") : null;
        this.query.project = project.length ? project.join(",") : null;
      }, 10);
      // e.forEach((item) => {
      //   institution.push(item[2] || item[1] ? "" : item[0]);
      //   country.push(item[2] ? "" : item[1]);
      //   project.push(item[2] || "");
      // });
    },
    
  },
  components: {
    tripdialog,
  },
};
</script>

<style scoped>
.container section {
  border-top: 1px solid #e5e5e5;
  padding-top: 20px;
  padding-bottom: 10px;
  position: relative;
}
.container section::before {
  content: attr(data-text);
  font-size: 16px;
  color: #999;
  font-weight: normal;
  position: absolute;
  left: 0%;
  top: 0;
  transform: translateY(-50%);
  background: #eef5fe;
  padding-right: 15px;
}
.container > section {
  border: none;
  padding: 0;
}
>>> .el-radio-group {
  margin-right: 15px;
}
.fold {
  font-size: 14px;
  color: #409eff;
  text-align: center;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
}
.fold i {
  animation: bounce 1s linear infinite;
}
@keyframes bounce {
  0% {
    transform: translateY(0);
  }
  20% {
    transform: translateY(15%);
  }
  40% {
    transform: translateY(0);
  }
  80% {
    transform: translateY(-15%);
  }
  100% {
    transform: translateY(0);
  }
}
</style>